@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

@font-face {
    font-family: 'Poppins-Bold';
    src: url('../public/fonts/Poppins-Bold.ttf');
}

@font-face {
    font-family: 'Poppins-ExtraBold';
    src: url('../public/fonts/Poppins-ExtraBold.ttf');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../public/fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../public/fonts/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: 'Proxima Nova Bold';
    src: url('../public/fonts/Proxima-Nova-Bold.otf');
}

@font-face {
    font-family: 'Proxima Nova Regular';
    src: url('../public/fonts/ProximaNova-Regular.otf');
}

.poppins-bold {
    font-family: 'Poppins-Bold' !important;
}

.poppins-medium {
    font-family: 'Poppins-Medium' !important;
}

.poppins-extrabold {
    font-family: 'Poppins-ExtraBold' !important;
}

.poppins-semibold {
    font-family: 'Poppins-SemiBold' !important;
}

.proxima-regular {
    font-family: 'Proxima Nova Regular' !important;
}

.proxima-bold {
    font-family: 'Proxima Nova Bold' !important;
}

.btn-primary {
    --bs-btn-bg: #EDB80F !important;
    --bs-btn-border-color: #EDB80F !important;
}
.get-in-touch a {
    color: #EDB80F !important;
}

@keyframes floater {
    0% {
        transform: translateY(-10%);
        transition: ease 0.5s;
    }

    50% {
        transform: translateY(10%);
        transition: ease 0.5s;
    }
}

.App-header {
    z-index: 2;
    background-color: white;
    top: 0;
}

.btn.floating {
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    padding: 0;
    width: 65px;
    height: 65px;
    box-shadow: 0 0 10px 0px #00000082;
    transform: translateY(-10%);
    animation: floater 1.5s infinite;
    transition: ease 0.5s;
    line-height: 62px;

    i {
        letter-spacing: normal;
        font-size: 32px;
    }
}

.policy {
    max-width: 1200px;
    margin: 108px auto 100px;
    padding: 0 20px;
    h1 {
        margin-bottom: 30px;
        text-align: center;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .navbar {
        .navbar-toggler {
            left: 12px;
            padding: 0;
            border: none;
            display: flex;
            flex-direction: column;
            height: 50px;
            width: 40px;
            top: 30px;

            &:focus {
                box-shadow: none;
            }
        }

        .navbar-brand img {
            height: 50px;
        }

        .menubar-line {
            border-color: #cba041;
            border-width: 2px;
            display: block;
            height: 1px;
            width: 100%;
            border-style: solid;
            margin-bottom: 5px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;

            &:last-of-type {
                width: 60%;
            }
        }
    }

    .section-title {
        font-size: 1.25rem;
    }

    .section-subtitle {
        font-size: 1.75rem;
        color: #EDB80F;
    }

    body .btn-primary {
        border-radius: 30px;
        letter-spacing: 5px;
        padding: 10px 24px;
        margin: 24px 0;
    }

    .App-footer {
        background: #f0f0f0;

        .footer-left, .footer-right {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .footer-logo img {
            width: 150px;
        }

        address {
            font-size: 14px;
        }

        .quick-links {
            border-left: 1px solid #EDB80F;

            a {
                text-decoration: none;
                margin: 5px 0;
                font-family: 'Poppins-SemiBold';
                color: black;
            }
        }

        .social-links {
            i {
                margin: 0 25px;
                font-size: 1.5rem;
                color: #EDB80F;
            }
        }
    }

    body .offcanvas {
        background-color: #EDB80F;

        .nav-link {
            font-family: 'Poppins-SemiBold';
            color: white;
            text-align: center;
            font-size: 1.75rem;
        }

        .navbar-nav .dropdown-menu {
            background-color: transparent;
            display: block;
            height: 0;
            padding: 0;
            opacity: 0;
            transition: height opacity 0.3s ease;

            &.show {
                height: 240px;
                opacity: 1;
            }
        }
    }

    .site-hero {
        height: 100vh;

        .carousel-item {
            background-size: cover;
            background-position: center;
            height: 100vh;
        }
    }

    .section-video {
        .ratio {
            border-radius: 20px;
            overflow: hidden;
        }
    }

    .other-projects {
        background-color: #EDB80F;

        .line-left,
        .line-right {
            border: 2px solid white;
        }

        .carousel {
            .carousel-caption {
                position: static;
                text-transform: uppercase;
                &:hover, &:active {
                    color: white;
                    background-color: #EDB80F;
                    border-color: #EDB80F;
                }
            }

            .carousel-item {
                text-align: center;

                img {
                    border-radius: 20px;
                    padding: 0;
                }
            }
        }
    }

    .about-us {
        text-align: center;

        .about-hero {
            background-image: url('../public/images/about.jpg');
            height: 100vh;
            background-size: cover;
        }

        .line-left {
            display: block !important;
        }

        .actions {
            display: flex !important;
            justify-content: space-around;

            .btn {
                width: 150px;
                font-size: 14px;
                margin: 10px 0;
                letter-spacing: normal;

                &.btn-outline-dark {
                    color: #EDB80F;
                    border-color: #EDB80F;
                    background-color: white;
                }
            }
        }
    }

    .contact {
        padding: 120px 0 30px;
        background-image: url('../public/images/bg.png');
        background-size: cover;

        .input-group-text {
            width: 120px;
            justify-content: center;
            color: white;
            background-color: #EDB80F;
            border-color: #EDB80F;
        }

        .form-control {
            border-color: #EDB80F;
        }

        .btn-primary {
            margin: 30px auto;
            display: block;
            letter-spacing: normal;
        }
    }
    .animated-container {
        display: inline-flex !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .mw-50 {
        max-width: 50%;
    }
    .App-header {
        background-color: white;
    }
    .animated-container {
        display: inline-flex !important;
    }
    .navbar {
        font-family: 'Poppins-Semibold';
        text-transform: uppercase;
        .navbar-brand {
            img {
                height: 50px;
            }
        }
        .navbar-nav {
            > .nav-link, > .nav-item {
                margin-left: 25px;
                font-size: 14px;
                .dropdown-menu {
                    .dropdown-item {
                        padding-right: 20px;
                        padding-left: 20px;
                        color: var(--bs-nav-link-color) !important;
                    }
                }
            }
        }
    }
    .section-title {
        font-size: 1rem;
    }

    .section-subtitle {
        font-size: 1.75rem;
        color: #EDB80F;
    }

    body .btn-primary {
        border-radius: 30px;
        letter-spacing: 5px;
        padding: 10px 24px;
        margin: 24px 0;
    }

    .App-footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        
        .footer-left {
            background: #f0f0f0;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .footer-right {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .footer-logo img {
            width: 250px;
        }

        address {
            font-size: 14px;
        }

        .quick-links {
            border-left: 1px solid #EDB80F;

            a {
                text-decoration: none;
                margin: 5px 0;
                font-family: 'Poppins-SemiBold';
                color: black;
            }
        }

        .social-links {
            i {
                margin: 0 25px;
                font-size: 1.5rem;
                color: #EDB80F;
            }
        }
    }
    .site-hero {
        height: calc(100vh - 100px);
        margin-top: 100px;
        overflow: hidden;

        .carousel-item {
            background-size: cover;
            background-position: center;
            height: 100vh;
        }
    }

    .section-video {
        .ratio {
            border-radius: 20px;
            overflow: hidden;
            margin: 50px auto;
        }
    }

    .other-projects {
        background-color: #EDB80F;

        .line-left,
        .line-right {
            border: 2px solid white;
        }

        .proj-wrapper {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 40px;
            .proj-container {
                img {
                    border-radius: 16px;
                }
            }
        }
    }
    .about-us {
        text-align: center;

        .about-hero {
            background-image: url('../public/images/desktop/about.png');
            height: 100vh;
            background-size: cover;
        }

        .container {
            padding-top: 3rem;
            padding-bottom: 3rem;
            p {
                max-width: 800px;
                margin: auto;
                font-size: 1.5rem;
            }
            .section-title {
                margin-bottom: 2rem;
                font-size: 2.5rem;
            }
            .section-subtitle {
                font-size: 2.75rem;
            }
        }

        .line-left {
            display: block !important;
        }

        .actions {
            display: flex !important;
            justify-content: center;

            .btn {
                width: 150px;
                font-size: 14px;
                margin: 10px 20px;
                letter-spacing: normal;

                &.btn-outline-dark {
                    color: #EDB80F;
                    border-color: #EDB80F;
                    background-color: white;
                }
            }
        }
        .section-experience {
            .counters-wrapper {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }
    }
    .contact {
        padding: 120px 0 30px;
        background-image: url('../public/images/bg.png');
        background-size: cover;
        form {
            max-width: 500px;
            margin: auto;
        }

        .input-group-text {
            width: 120px;
            justify-content: center;
            color: white;
            background-color: #EDB80F;
            border-color: #EDB80F;
        }

        .form-control {
            border-color: #EDB80F;
        }

        .btn-primary {
            margin: 30px auto;
            display: block;
            letter-spacing: normal;
        }
    }
}