/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .hero {
        height: 100vh;
        background-image: url('../../public/images/Banner.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .hero-title {
            text-shadow: 1px 1px 6px #2d2b2b;

            h2 {
                font-size: 1.75rem;
            }

            h1 {
                font-size: 2.5rem;
            }
        }

        .btn-primary {
            border-radius: 30px;
            letter-spacing: 5px;
            padding: 10px 24px;
            margin: 24px 0;
        }

        .hero-contact {
            border: 1px solid white;
            padding: 5px 50px;
            border-radius: 50px;
            margin-top: 30px;
            width: 80%;

            a {
                display: inline-block;
                font-size: 26px;
                color: white;

                span {
                    display: none;
                }

                i {
                    text-shadow: 1px 1px 6px #2d2b2b;
                }
            }

            .phone-icon {
                display: none;
            }
        }
    }

    .section-experience {
        .counters-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        background-color: #EDB80F;

        .counter {
            span {
                font-size: 3.5rem;
            }
        }
    }

    .section-book-visit {
        background-image: url('../../public/images/Footer\ Banner.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        h2 {
            font-size: 0.75rem;
        }

    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .hero {
        height: 100vh;
        background-image: url('../../public/images/Banner.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .hero-title {
            text-shadow: 1px 1px 6px #2d2b2b;

            h2 {
                font-size: 1.75rem;
            }

            h1 {
                font-size: 2.5rem;
            }
        }

        .btn-primary {
            border-radius: 30px;
            letter-spacing: 5px;
            padding: 10px 24px;
            margin: 24px 0;
        }

        .hero-contact {
            border: 1px solid white;
            padding: 5px 50px;
            border-radius: 50px;
            margin-top: 30px;
            width: 40%;
            position: relative;

            a {
                display: inline-block;
                color: white;
                text-decoration: none;

                i {
                    font-size: 26px;
                    text-shadow: 1px 1px 6px #2d2b2b;
                    vertical-align: middle;
                }

                span {
                    vertical-align: middle;
                    margin-left: 5px;
                    letter-spacing: 2px;
                }
            }

            .phone-icon {
                position: absolute;
                height: 80px;
                top: -20px;
                right: -40px;
                display: block;
            }
        }
    }

    .section-experience {
        .counters-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        background-color: #EDB80F;

        .counter {
            span {
                font-size: 3.5rem;
            }
        }
    }

    .section-book-visit {
        background-image: url('../../public/images/Footer\ Banner.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        h2 {
            font-size: 0.75rem;
        }

    }
}