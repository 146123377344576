.why-choose-us {
    background-color: #F0F0F0;
}
.line-right {
    border-bottom: 1px solid black;
    display: inline-block;
    flex-grow: 1;
    margin-left: 15px;
}
.line-left {
    margin-left: 0;
    margin-right: 15px;
}
.carousel {
    .carousel-caption {
        bottom: -25px;
        padding: 6px 12px;
        border-radius: 30px;
    }
    .carousel-item img {
        padding-bottom: 25px;
    }
}
.feature-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.feature-container {
    img {
        width: 60%;
        margin: 20px 0;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .why-choose-us {
        .feature-wrapper {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }
    }
}
